/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-11-27",
    versionChannel: "nightly",
    buildDate: "2023-11-27T00:14:55.526Z",
    commitHash: "23e7a653d8de5b9d4b1b727c2a6c2da0e277ba11",
};
